<template>
  <div :class="floorList.length ? 'device_view' : '_device_view'">
    <!-- 运营商 项目 选择 -->
    <div class="tree_box">
      <div class="operator_select" v-supAdmin>
        <div>
          <el-select v-model="id_operator" placeholder="请选择运营商" class="w100" @change="selectOperator">
            <el-option v-for="item in allOperatorList" :label="item.name_operator" :value="item.id_operator"
              :key="item.id_operator"></el-option>
          </el-select>
        </div>
      </div>

      <div class="device_view_tree_box" v-if="showTree">
        <el-tree ref="treeRef" lazy :props="{ label: 'label', isLeaf: 'leaf' }" accordion @node-click="treeNodeClick"
          :load="loadNode" highlight-current>
        </el-tree>
      </div>
    </div>
    <!-- 占位 -->
    <div class="line"></div>

    <!-- 空调视图, 操作 -->
    <div class="content_box">
      <div class="floor_box" v-if="floorList.length">
        <el-scrollbar style="width: 100%" :horizontal="true">
          <div class="inner_box">
            <div class="floor" :class="floor === '' ? 'current' : ''" @click="changeFloor('')">
              全部
            </div>
            <div class="floor" :class="floor === item.floor ? 'current' : ''" v-for="item in floorList" :key="item.floor"
              @click="changeFloor(item.floor)">
              {{ item.floor }}F
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="view_and_btns">
        <!-- 空调视图 -->
        <div class="air_view">
          <div class="all_select">
            <el-checkbox v-model="isSelectAll">全选</el-checkbox>
          </div>
          <div class="view_list">
            <el-scrollbar style="height: 100%">
              <div class="scroll_view">
                <!-- 遍历的空调 -->
                <div class="view" v-for="item in deviceList" :key="item.id" :class="{
                  selected: item.selected ? true : false,
                  is_err: item.fault ? true : false
                }" @click="item.selected = !item.selected">
                  <div class="img_box">
                    <!-- 空调离线状态 -->
                    <template v-if="item.state === 0">
                      <img src="../../../assets/air_state_4.png" alt="" />
                    </template>
                    <!-- 空调在线状态 三种情况 -->
                    <template v-else>
                      <img v-if="item.run === 1" src="../../../assets/air_state_1.png" alt="" />
                      <img v-else src="../../../assets/air_state_2.png" alt="" />
                    </template>
                  </div>
                  <div class="action_box">
                    <el-popover v-if="item.task.program && item.task.program.id" placement="top-start" trigger="hover">
                      <div class="popup_rule_box">
                        <div class="state">
                          {{ item.run ? '运行' : '待机' }}状态
                        </div>
                        <div class="rule_item">
                          <div class="label">控制规则：</div>
                          <div class="value">{{ item.task.name }}</div>
                        </div>
                        <div class="rule_item">
                          <div class="label">生效程序：</div>
                          <div class="value">{{ item.task.program.name }}</div>
                        </div>
                        <div class="rule_item">
                          <div class="label">启用时段：</div>
                          <div class="value">
                            {{ getRunDate(item.task.program) }}
                          </div>
                        </div>
                        <div class="rule_item">
                          <div class="label">运行时间：</div>
                          <div class="value" v-if="item.task.program.time">
                            <div v-for="it in item.task.program.time" :key="it.id">
                              {{ it.start_time.padStart(8, 0).substr(0, 5) }}-{{
                                it.end_time.padStart(8, 0).substr(0, 5)
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <img class="rule_icon" slot="reference" src="../../../assets/device_view_rule.png" alt="" />
                    </el-popover>

                    <img v-else class="rule_icon" src="../../../assets/device_view_rule_offline.png" alt="" />

                    <el-tooltip class="item" effect="light" :content="item.addr" placement="top">
                      <span class="addr">{{ item.addr }}</span>
                    </el-tooltip>

                    <img v-if="item.locked" class="lock" src="../../../assets/locked.png" alt="" />
                    <img v-else class="lock" src="../../../assets/unlock.png" alt="" />
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="pagination_box">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="changePage"
              :current-page="page_id + 1" hide-on-single-page :page-size="20">
            </el-pagination>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="btns">
          <!-- 在线 总数... 设备统计 -->
          <div class="device_count">
            <div class="count">
              <div class="num">
                <span class="online">{{ countData.cnt_online || '0' }}</span>
                <span class="split">/</span>
                <span class="count">{{ countData.cnt_all || '0' }}</span>
              </div>
              <div class="desc">
                <span class="online">在线</span>
                <span class="split">/</span>
                <span class="count">总数</span>
              </div>
            </div>

            <div class="every_count">
              <div class="item">
                <img src="../../../assets/view_state_1.png" alt="" />
                <div class="desc">
                  <div class="num">{{ countData.cnt_run }}</div>
                  <div class="tip">运行</div>
                </div>
              </div>

              <div class="item">
                <img src="../../../assets/view_state_2.png" alt="" />
                <div class="desc">
                  <div class="num">
                    {{
                      countData ? countData.cnt_online - countData.cnt_online_run : '0'
                    }}
                  </div>
                  <div class="tip">待机</div>
                </div>
              </div>

              <div class="item">
                <img src="../../../assets/view_state_3.png" alt="" />
                <div class="desc">
                  <div class="num">{{ countData.cnt_fault || '0' }}</div>
                  <div class="tip">故障</div>
                </div>
              </div>

              <div class="item">
                <img src="../../../assets/view_state_4.png" alt="" />
                <div class="desc">
                  <div class="num">{{ countData.cnt_offline || '0' }}</div>
                  <div class="tip">离线</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 分割线占位 -->
          <div class="split_line"></div>
          <!-- 按钮盒子 -->
          <div class="btn_box">
            <div class="button button1" @click="controlDevice(1)">开机</div>
            <div class="button button2" @click="controlDevice(2)">关机</div>
            <div class="button button3" @click="controlDevice(3)">锁定</div>
            <div class="button button4" @click="controlDevice(4)">解锁</div>
          </div>
        </div>
      </div>
    </div>

    <Alert ref="alertRef" @confirm="confirm" />
  </div>
</template>

<script>
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'
import building from '@/mixins/building.js'
import floor from '@/mixins/floor.js'
import {
  GetRealDeviceListApi,
  GetDeviceCountApi,
  ControlSomeDeviceApi,
  LockSomeDeviceApi,
  UnlockSomeDeviceApi
} from '@/api'
import Alert from '@/components/alert/alert.vue'

export default {
  name: 'device_view',
  mixins: [operator, project, building, floor],
  components: { Alert },
  data() {
    return {
      // 权限级别
      level: '',
      // 运营商
      id_operator: '',
      // 项目
      id_project: '',
      // 楼栋
      id_building: '',
      // 楼层
      floor: '',
      // 树级别 0项目 1楼栋
      treeLevel: '',
      page_id: 0,
      total: 0,
      // 是否显示树节点
      showTree: true,
      // 设备数据
      deviceList: [],
      // 统计数据
      countData: ''
    }
  },
  methods: {
    // 页码变化
    changePage(page) {
      this.page_id = page - 1
      this.getDeviceList()
    },

    // 树节点被点击
    treeNodeClick(row, { level }) {
      if (level === 1) {
        this.id_project = row.id
        this.id_building = ''
        this.floor = ''
        this.getData()
      } else if (level === 2) {
        this.floor = ''
        this.id_building = row.id
        this.getData()
      }
    },

    // 加载节点
    async loadNode(node, resolve) {
      const level = node.level
      const row = node.data
      if (level === 0) {
        const params = {}
        if (this.id_operator) {
          params.id_operator = this.id_operator
        }
        const data = await this.getProjectList(params)
        const treeData = []
        data.map(i => {
          treeData.push({
            label: i.name_project,
            id: i.id
          })
        })
        resolve(treeData)
      } else {
        const data = await this.getBuildingList({
          id_project: row.id
        })
        const treeData = []
        data.map(i => {
          treeData.push({
            label: i.name_building,
            id: i.id,
            leaf: true
          })
        })
        resolve(treeData)
      }
    },

    // 选中运营商
    async selectOperator() {
      this.id_project = ''
      this.id_building = ''
      this.floor = ''
      this.showTree = false
      this.$nextTick(() => {
        this.showTree = true
        this.getData()
      })
    },

    // 获取空调数据 和 统计数据 入口函数
    getData() {
      this.getDeviceList()
      this.getCountData()
      if (this.id_building) {
        const params = {
          id_project: this.id_project,
          id_building: this.id_building
        }
        this.getFloorList(params)
      } else {
        this.buildingList = []
      }
    },

    // 加载设备列表
    async getDeviceList() {
      const params = {
        page_id: this.page_id,
        page_num: 20
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.id_building) {
        params.id_building = this.id_building
      }
      if (this.floor) {
        params.floor = this.floor
      }
      const { ret, data, msg } = await GetRealDeviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      data.data.map(i => {
        i.selected = false
      })
      this.deviceList = data.data
    },

    // 改变楼层
    changeFloor(floor) {
      this.page_id = 0
      this.floor = floor
      this.getData()
    },

    // 加载统计数据
    async getCountData() {
      const params = {}
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.id_building) {
        params.id_building = this.id_building
      }
      if (this.floor) {
        params.floor = this.floor
      }
      const { ret, data, msg } = await GetDeviceCountApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.countData = data
    },

    // 控制设备 type: 1开 2关  3锁定 4解锁
    controlDevice(type) {
      const selected = this.deviceList.filter(i => {
        return i.selected === true
      })
      if (!selected.length) {
        return this.$message.warning('请勾选设备后再试!')
      }
      const fonts = [
        '批量启停是针对选中设备进行指令批量下发操作',
        '操作结果与本次请求返回结果无关, 请以实际为准'
      ]
      if (type === 1) {
        this.$refs.alertRef.alert(
          {
            title: '批量开机提醒',
            msg: ['您确定要批量开启设备吗?(已开启设备不会受到影响!)', ...fonts]
          },
          selected,
          1
        )
      } else if (type === 2) {
        this.$refs.alertRef.alert(
          {
            title: '批量关机提醒',
            msg: ['您确定要批量关闭设备吗?(已关闭设备不会受到影响!)', ...fonts]
          },
          selected,
          2
        )
      } else if (type === 3) {
        this.lockDevice(selected)
      } else {
        this.unlockDevice(selected)
      }
    },

    // 弹出框点击确定
    confirm({ info, type }) {
      if (type === 1) {
        this.openOrCloseDevice(info, 1)
      } else if (type === 2) {
        this.openOrCloseDevice(info, 0)
      }
    },

    // 批量开启/关闭设备
    async openOrCloseDevice(info = [], run) {
      const deviceIds = []
      info.map(i => {
        deviceIds.push(i.id_device)
      })
      const params = {
        device_array: deviceIds,
        run
      }
      const { ret, data, msg } = await ControlSomeDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('指令下发成功!')
    },

    // 批量锁定设备
    async lockDevice(list = []) {
      const params = []
      list.map(i => {
        params.push(i.id_device)
      })
      const { ret, data, msg } = await LockSomeDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('指令下发成功')
    },

    // 批量解锁设备
    async unlockDevice(list = []) {
      const params = []
      list.map(i => {
        params.push(i.id_device)
      })
      const { ret, data, msg } = await UnlockSomeDeviceApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('指令下发成功')
    },

    // 计算运行时长
    getRunDate(program) {
      if (!program.start_date) {
        return '--'
      }
      const startStr = (program.start_date + '').padStart(4, '0')
      const endStr = (program.end_date + '').padStart(4, '0')
      return `${startStr.substr(0, 2)}-${startStr.substr(
        2,
        4
      )}——${endStr.substr(0, 2)}-${endStr.substr(2, 4)}`
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getData()
  },
  computed: {
    // 计算是否全选
    isSelectAll: {
      get() {
        return (
          this.deviceList.filter(i => i.selected).length ===
          this.deviceList.length
        )
      },
      set(flag) {
        return this.deviceList.map(i => {
          i.selected = flag
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.device_view {
  height: 960px;
  background-color: #21262f;
  display: flex;
  padding: 10px;
}

._device_view {
  @extend .device_view;
  height: 912px;

  .tree_box,
  .content_box {
    min-height: 892px;
  }
}

.tree_box {
  flex: 0 0 256px;
  min-height: 940px;
  background-color: #252b3b;

  .operator_select {
    height: 86px;
    width: 100%;
    padding: 0 30px 0 26px;
    display: flex;
    align-items: center;
  }

  .tree_box {
    width: 225px;
    margin: 0 auto;
  }
}

.line {
  flex: 0 0 20px;
  height: 100px;
  background-color: #21262f;
}

.content_box {
  flex: 1;
  min-height: 940px;

  .floor_box {
    width: 100%;
    height: 48px;
    overflow-x: auto;
    display: flex;

    .inner_box {
      display: flex;
      flex-wrap: nowrap;

      .floor {
        flex: 0 0 60px;
        height: 48px;
        background: #252b3b;
        margin-right: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a657f;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        cursor: pointer;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &.current {
          background-color: #50e399;
          color: #fff;
        }
      }
    }
  }

  .view_and_btns {
    min-height: 892px;
    display: flex;

    .air_view {
      flex: 1;

      .all_select {
        height: 52px;
        display: flex;
        align-items: center;
      }

      .view_list {
        height: 800px;

        .scroll_view {
          height: 100%;
          display: flex;
          flex-wrap: wrap;

          .view {
            width: 199px;
            height: 219px;
            background: #252b3b;
            border-radius: 0px;
            margin: 10px;
            display: flex;
            flex-direction: column;
            border: 1px solid #21262f;

            &.is_err {
              position: relative;

              &::before {
                content: '故障';
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
                width: 40px;
                height: 18px;
                box-sizing: border-box;
                background-color: #e4576a;
                line-height: 18px;
                font-size: 11px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                color: #fefefe;
                border-radius: 0 0 0 18px;
                text-align: center;
                padding-left: 6px;
              }
            }

            &.selected {
              position: relative;
              border: 1px solid #50e399;

              &::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 2;
                width: 36px;
                height: 36px;
                background: url('../../../assets/device_view_selected.png') no-repeat;
                background-size: cover;
              }
            }

            .img_box {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: auto;
              }
            }

            .action_box {
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;

              .rule_icon {
                width: 16px;
                height: 16px;
              }

              .addr {
                width: 80px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7e9be6;
                margin: 0 10px;
              }

              .lock {
                width: 14px;
                height: 14px;
                vertical-align: middle;
              }
            }
          }

          @media screen and (max-width: 1450px) {
            .view {
              width: 170px;
              height: 178px;

              &.selected::after {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }

      .pagination_box {
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
    }

    .btns {
      flex: 0 0 237px;
      height: 100%;
      height: 892px;
      padding: 10px 0;
      display: flex;
      flex-direction: column;

      .device_count {
        flex: 0 0 470px;
        background-color: #252b3b;
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        .count {
          flex: 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .num {
            font-size: 40px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            display: flex;

            .online {
              color: #0062ff;
            }

            .split {
              color: #5b6680;
            }

            .count {
              color: #5b6680;
            }
          }

          .desc {
            display: flex;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #5b6680;
            margin-top: 8px;
          }
        }

        .every_count {
          flex: 9;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &::before,
          &::after {
            content: '';
            display: block;
          }

          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 52px 0 43px;

            img {
              width: 52px;
              height: 26px;
            }

            .desc {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .num {
                font-size: 24px;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #5b6680;
                line-height: 1;
              }

              .tip {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #5b6680;
                margin-top: 14px;
              }
            }
          }
        }
      }

      .split_line {
        flex: 0 0 12px;
      }

      .btn_box {
        flex: 0 0 390px;
        background-color: #252b3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &::after,
        &::before {
          content: '';
          display: block;
        }

        .button {
          width: 140px;
          height: 40px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &1 {
            border: 1px solid #50e399;
            color: #50e399;
          }

          &2 {
            border: 1px solid #a8bad6;
            color: #a8bad6;
          }

          &3 {
            border: 1px solid #7d7df9;
            color: #7d7df9;
          }

          &4 {
            border: 1px solid #dddddf;
            color: #dcdedf;
          }
        }
      }
    }
  }
}

.popup_rule_box {
  width: 284px;
  height: 194px;
  background: #ffffff;
  padding: 9px 25px;
  overflow: hidden;

  .state {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0062ff;
  }

  .rule_item {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5b6680;
    line-height: 2;
    display: flex;

    .label {
      flex: 0 0 70px;
    }

    .value {
      flex: 1;
    }
  }
}
</style>
<style lang="scss">
.device_view_tree_box {
  width: 225px;
  margin: 0 auto;

  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 50px;
      background-color: transparent;
      // background-color: #21262f !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
      background-color: #21262f !important;
      color: #50e399;
      border-radius: 6px;
    }
  }
}
</style>
